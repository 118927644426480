import React, { useState, useEffect } from "react";
import AnimContainer from "../components/AnimContainer";
import VariantsContainer from "../components/VariantsContainer";
import { AnimatePresence, motion } from "framer-motion";
import { slideDownVariants, opacityVariants } from "../utils/variants";
import HeadingComp from "../components/HeadingComp";
import P from "../components/AboutPage/P";
import { calculateAge } from "../utils/utils";
import { Link } from "react-router-dom";
import PageMetaTags from "../components/PageMetaTags";
import ReactGA from "react-ga";
import { resumeLink } from "../utils/variables";

const AboutPage = () => {
  const [showShortVersion, setShowShortVersion] = useState(false);

  const age = calculateAge(new Date(1995, 9, 7));

  useEffect(() => window.scrollTo(0, 0), []);

  const description = `i'm thulane khumalo a {age} year old developer from the east side
  of johannesburg.`;

  const aboutMeBtnStyle = () =>
    document.body.classList.contains("dark-mode")
      ? { background: "#fff", color: "#000" }
      : {};

  const handShowing = document
    .querySelector("body")
    .classList.contains("cursor-off");

  const cursorStyles = handShowing ? {} : { cursor: "pointer" };

  return (
    <VariantsContainer classTitle="about-page-container">
      <PageMetaTags
        title="About Me | Thulane's Portfolio"
        description={description}
      />
      <div className="page-heading-container">
        <HeadingComp heading="About Me" />
      </div>
      <div className="about-me-text-container">
        <AnimContainer>
          <motion.h3
            className="about-text-version"
            style={cursorStyles}
            onClick={() => setShowShortVersion((prev) => !prev)}
            variants={slideDownVariants(0.4, 0.1)}
          >
            {showShortVersion ? "show complete version" : "show short version"}
          </motion.h3>
        </AnimContainer>

        {showShortVersion ? (
          <>
            <P index={1} key="dasd" className="about-me-text">
              I'm Thulane Khumalo a developer from the east side of
              Johannesburg.
            </P>
            <AnimatePresence>
              <P index={2} className="about-me-text">
                I started this journey in 2017, making HTML and CSS based
                websites through wordpress. In the many years since, I've
                improved my skills immensely adding JavaScript and TypeScript in
                addition to Python to create richer web applications. I lean on
                frontend and backend frameworks like React JS, Django and Node
                JS, as well as cloud technology to add functionality and
                interactivity necessary in modern web apps. Crafting entire
                projects isn't easy and as a result of overcoming the challenges
                I faced along the way I now have skills and experience in every
                step of the development pipeline. Stemming from wire-framing and
                web design, up to frontend and backend development all the way
                up to monolithic and distributed (micro-service) deployment. To
                get a more complete scope of my abilities please visit my{" "}
                <Link to="/skills">skills section </Link>
                and to see the extent of my experience open my{" "}
                <Link to="/projects">projects section</Link>.
              </P>
            </AnimatePresence>
            <P index={3} className="about-me-text">
              The tech environment and developement landscape is truly something
              I love and every moment spent in it is a fulfilling experience for
              me. Today I'm a capable developer, who's is proficient, consistent
              and relentless in his efforts to improve professionally and
              personally. Tomorrow I hope to be working with/for an entity
              that's as passionate about using software to make a positive mark
              on the world as I am. If that sounds like you, I'd love it if you
              reached out.
            </P>
          </>
        ) : (
          <>
            <P key="dasd" className="about-me-text" index={1}>
              I'm Thulane Khumalo a {age} year old developer from the east side
              of Johannesburg.
            </P>
            <P className="about-me-text" index={2}>
              I guess my programming journey starts as a mechanical engineering
              drop out. A major part of engineering as a discipline is
              projecting trajectories and the outcomes of given paths and
              drawing relevant conclusions from them. I ironically used that
              knowledge to assert that I didn't like the trajectory of my life.
              Engineering, in the local landscape just didn't provide the type
              of opportunity I'd be satisfied with. What it did give me however
              is Python.
            </P>

            <P key="dfsfdf" className="about-me-text" index={3}>
              Using Python, I said <span>hello</span> to a <span>world</span> I
              now know I'll live in and love forever. My nature is to build
              things and my fulfillment is to enjoy that process. The software
              development environment is exactly the perfect balance of complex
              and challenging whilst being creative and inventive and also fun
              and fulfilling. I love the autonomy and expressiveness of this
              space. I literally built a programme that uses audio recognition
              so my nieces can play the chrome-dinosaur game and flappy bird by
              yelling jump or clapping. I instead use my super powers for
              commercial good. I build fully featured applications that help
              businesses and individuals meet their personal or commercial needs
              while asynchronously learning to do it more proficiently and
              effectively.
            </P>
            <P className="about-me-text" index={4}>
              I started as a web developer in 2017, making wordpress websites
              leveraging HTML and CSS. In the many years since, I've improved my
              skills immensely adding JavaScript and TypeScript in addition to
              Python to create richer web applications. I lean on frontend and
              backend frameworks like React JS, Django and Node JS, as well as
              cloud technology to add functionality and interactivity necessary
              in modern web apps. Crafting entire projects isn't easy and as a
              result of overcoming the challenges I faced along the way I now
              have skills and experience in every step of the development
              pipeline. Stemming from wire-framing and web design, up to
              frontend and backend development all the way up to monolithic and
              distributed (micro-service) deployment.
            </P>
            <P className="about-me-text" index={5}>
              To get a more complete scope of my abilities please visit my{" "}
              <Link to="/skills">skills section </Link>and to see the extent of
              my experience open my <Link to="/projects">projects section</Link>
              .
            </P>
            <P className="about-me-text" index={6}>
              Today I'm a capable developer, who's is proficient, consistent and
              relentless in his efforts to improve professionally and
              personally. Tomorrow I hope to be working with/for an entity
              that's as passionate about using software to make a positive mark
              on the world as I am. If that sounds like you, I'd love it if you
              reached out.
            </P>
          </>
        )}
      </div>
      <div className="about-cta-btns">
        <Link to="contacts">
          <motion.button
            style={aboutMeBtnStyle()}
            variants={opacityVariants()}
            className="about-contacts-btn"
          >
            contacts
          </motion.button>
        </Link>
        <a href={resumeLink} target="_blank">
          <motion.button
            style={aboutMeBtnStyle()}
            variants={opacityVariants()}
            className="see-resume-btn"
            onClick={() =>
              ReactGA.event({
                category: "Open Resume",
                action: "Open Resume (About Me)",
              })
            }
          >
            see resume
          </motion.button>
        </a>
      </div>
    </VariantsContainer>
  );
};

export default AboutPage;
