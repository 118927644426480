import React, { useState } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import {
  slideDownVariants,
  slideUpVariants,
  opacityVariants,
} from "../utils/variants";
import VariantsContainer from "./VariantsContainer";
import AnimContainer from "./AnimContainer";
import { motion } from "framer-motion";
import CircleText from "./CircleText";
import {
  themes,
  colourThemeName,
  lightThemeName,
  darkThemeName,
  resumeLink,
} from "../utils/variables";

const Header = ({ selectedTheme, setSelectedTheme }) => {
  const menuItems = ["home", "projects", "skills", "contacts", "about"];
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const handleOverlayClick = () => {
    setSideMenuOpen(false);
  };

  const themeStyle = (theme) => {
    return {
      backgroundColor: theme.colour,
      // border: theme.name === selectedTheme.name ? "1px solid #000" : "none",
    };
  };

  const setMetaThemeColour = (colour) =>
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", colour);

  const handleThemeChange = (theme) => {
    const body = document.body;
    if (theme.name === darkThemeName) {
      body.classList.add("dark-mode");
      body.classList.remove("colour-theme");
      setSelectedTheme(darkThemeName);
      setMetaThemeColour("#000");
      return;
    } else if (theme.name === colourThemeName) {
      body.classList.add("colour-theme");
      body.classList.remove("dark-mode");
      setMetaThemeColour(colourThemeName);
      setSelectedTheme(colourThemeName);
      return;
    }
    body.classList.remove("dark-mode");
    body.classList.remove("colour-theme");
    setSelectedTheme(lightThemeName);
    setMetaThemeColour(lightThemeName);
  };

  const { roof, wall } =
    selectedTheme === colourThemeName
      ? {
          roof: "#fff",
          wall: "#fff",
        }
      : selectedTheme === lightThemeName
      ? {
          roof: "#000",
          wall: "#000",
        }
      : {
          roof: "#fff",
          wall: "#fff",
        };

  const overlayClass =
    "side-menu-overlay" + (sideMenuOpen ? "" : " overlay-closed");
  return (
    <VariantsContainer classTitle="header-container">
      <Link to="">
        <div className="header-home-btn-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45.04"
            height="43.89"
            viewBox="0 0 45.04 43.89"
          >
            <g
              id="Group_44"
              data-name="Group 44"
              transform="translate(-36 -17.903)"
            >
              <path
                id="Path_31"
                data-name="Path 31"
                d="M0,0H39.842s-.13,22.567,0,22.921c-9.83,1.433-20.914,1.14-26.871.81,0-.026-.323-8.708-.3-8.733-.311-2.643-3.861-2.643-4.354-2.643C7.128,12.277,4.9,13.685,5.054,14.7c0-.026.026,7.734,0,7.76-.052-.026-.868-.394-1.507-.6-.988-.32-2.276-.767-3.549-1.2C-.021,20.62,0,0,0,0Z"
                transform="translate(38.809 37.791)"
                fill={wall}
              />
              <path
                id="Path_32"
                data-name="Path 32"
                d="M22.538,1.9l22.5,19.122H0Z"
                transform="translate(36 16)"
                fill={roof}
              />
            </g>
          </svg>
        </div>
      </Link>
      <div className="themes-container">
        {themes.map((theme, index) => (
          <div key={theme.colour + "xjr"}>
            <CircleText
              handler={() => handleThemeChange(theme)}
              text={theme.name + "   "}
              innerCircle={theme.colour}
            />
          </div>
        ))}
      </div>

      <div
        onClick={() => setSideMenuOpen(false)}
        className={overlayClass}
      ></div>

      <div className="nav-items-container">
        {menuItems.map((item, index) => (
          <AnimContainer key={item + "dsw"}>
            <Link to={"/" + item}>
              <motion.h3
                variants={slideDownVariants(0.5, 0.1 + index / 10)}
                className="nav-item"
              >
                {item}
              </motion.h3>
            </Link>
          </AnimContainer>
        ))}
        <a href={resumeLink} target="_blank">
          <motion.h3
            onClick={() =>
              ReactGA.event({
                category: "Open Resume",
                action: "Open Resume (Header)",
              })
            }
            variants={slideDownVariants(0.5, 0.1 + 0.5)}
            className="nav-item"
          >
            resume
          </motion.h3>
        </a>
      </div>

      <div className="hamburger-container"></div>

      <div
        className={
          "side-menu-container" + (sideMenuOpen ? " side-menu-open" : "")
        }
      >
        <div
          onClick={() => {
            setSideMenuOpen((prev) => !prev);
          }}
          className="side-menu-toggle"
        >
          {sideMenuOpen ? "CLOSE" : "MENU"}
        </div>
        {menuItems.map((item, index) => (
          <div key={item + "sdsdf"}>
            <Link to={item}>
              <div onClick={handleOverlayClick} className="side-menu-item">
                {item}
              </div>
            </Link>
          </div>
        ))}
        <a href={resumeLink} target="_blank">
          <div
            style={{ color: "#5555cc", fontSize: 18 }}
            className="side-menu-item"
            onClick={() =>
              ReactGA.event({
                category: "Open Resume",
                action: "Open Resume (Mobile Header)",
              })
            }
            variants={slideDownVariants(0.5, 0.1 + 0.5)}
          >
            ﹡resume
          </div>
        </a>
      </div>

      {/* <Overlay handler={handleOverlayClick} toggleVal={sideMenuOpen} /> */}
    </VariantsContainer>
  );
};

export default Header;
