import React, { useState, useRef, useEffect } from "react";
import Hand from "./components/Hand";
import { Switch, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { AnimatePresence } from "framer-motion";
import Homepage from "./pages/Homepage";
import ProjectsPage from "./pages/ProjectsPage";
import ContactsPage from "./pages/ContactsPage";
import AboutPage from "./pages/AboutPage";
import HideHandBtns from "./components/HideHandBtns";
import { lightThemeName } from "./utils/variables";
import SkillsPage from "./pages/SkillsPage";
import ReactGA from "react-ga";

const App = () => {
  const location = useLocation();
  const [showHand, setShowHand] = useState(true);
  const [selectedTheme, setSelectedTheme] = useState(lightThemeName);
  const [showHandBtn, setShowHandBtn] = useState(false);
  const [mouseInit, setMouseInit] = useState({
    x: window.innerWidth,
    y: window.innerHeight / 2,
  });
  const fingerRef = useRef();

  const playAnim = () => {
    // fingerRef.current;
    fingerRef.current.gotoAndPlay(1);
    // particleRef.current.gotoAndPlay(1);
  };

  useEffect(() => {
    ReactGA.initialize("UA-185640333-2");
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    if (showHand) {
      body.classList.add("cursor-off");
    } else {
      body.classList.remove("cursor-off");
    }
  }, [showHand]);

  return (
    <div style={{ cursor: showHand && showHandBtn ? "none" : "initial" }}>
      {showHand && (
        <Hand
          mouseInit={mouseInit}
          fingerRef={fingerRef}
          showHandBtn={showHandBtn}
          setShowHandBtn={setShowHandBtn}
        />
      )}

      <div onClick={() => showHand && playAnim()} className="app-wrapper">
        <Header
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
        />
        <div className="app-container">
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.key}>
              <Route exact path="/projects" component={ProjectsPage} />
              <Route exact path="/contacts" component={ContactsPage} />
              <Route exact path="/about" component={AboutPage} />
              <Route exact path="/skills" component={SkillsPage} />
              <Route path="" component={Homepage} />
            </Switch>
          </AnimatePresence>
        </div>

        {showHandBtn && (
          <HideHandBtns
            showHand={showHand}
            setShowHand={setShowHand}
            mousePosHandler={setMouseInit}
          />
        )}
        <Footer selectedTheme={selectedTheme} />
      </div>
    </div>
  );
};

export default App;
