import React from "react";
import { motion } from "framer-motion";
import { opacityVariants, doNothingVariants } from "../../utils/variants";
import ReactGA from "react-ga";

const PRJViewBtns = ({ githubLink, siteLink, prjName }) => {
  return (
    <motion.div
      variants={doNothingVariants}
      initial="init"
      animate="anim"
      exit="exit"
    >
      {siteLink && (
        <motion.a
          variants={opacityVariants(undefined, 0.2)}
          href={siteLink}
          target="_blank"
        >
          <button
            onClick={() =>
              ReactGA.event({
                category: "Project Redirect",
                action: `${prjName} Live Project`,
                label: "Live Project Redirect",
              })
            }
            className="prj-view-btn"
          >
            live project
          </button>
        </motion.a>
      )}
      {githubLink && (
        <motion.a
          variants={opacityVariants(undefined, 0.1)}
          href={githubLink}
          target="_blank"
        >
          <button
            onClick={() =>
              ReactGA.event({
                category: "Project Redirect",
                action: `${prjName} Github`,
                label: "Github Redirect",
              })
            }
            className="prj-view-btn"
          >
            code
          </button>
        </motion.a>
      )}
    </motion.div>
  );
};

export default PRJViewBtns;
