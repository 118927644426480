import React, { useState } from "react";
import AnimContainer from "../components/AnimContainer";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { AnimatePresence, motion } from "framer-motion";
import {
  slideDownVariants,
  doNothingVariants,
  slideUpVariants,
  opacityVariants,
} from "../utils/variants";
import PageMetaTags from "../components/PageMetaTags";
import { resumeLink } from "../utils/variables";

const Homepage = () => {
  const homeLinks = [
    { text: "projects", link: "projects" },
    { text: "skills", link: "skills" },
    { text: "contacts", link: "contacts" },
    { text: "about me", link: "about" },
  ];

  const placeHolderVariants = {
    ...doNothingVariants,
    anim: {
      transition: {
        staggerChildren: 1.5,
      },
    },
  };

  const [homeLinkHoverIndex, setHomeLinkHoverIndex] = useState(null);

  const underlineVariants = {
    init: { width: 1 },
    anim: {
      width: "100%",
      transition: {
        duration: 0.25,
        type: "tween",
      },
    },
    exit: {
      width: 0,
    },
  };

  const description = "A front to back end web developer.";

  const description2 = "";

  return (
    <motion.div
      variants={placeHolderVariants}
      initial="init"
      animate="anim"
      exit="exit"
      className="homepage-container"
    >
      <PageMetaTags
        title="Thulane's Portfolio | Home"
        description={description + description2}
      />
      <AnimContainer>
        <motion.div
          variants={slideDownVariants(0.5)}
          className="home-description"
        >
          {description}
          <span>{description2}</span>
        </motion.div>
      </AnimContainer>

      <motion.div
        variants={opacityVariants(0.5, 0.5)}
        className="home-line-container"
      >
        <div className="home-line"></div>
      </motion.div>

      <div className="home-links-container">
        {homeLinks.map((linkObj, index) => (
          <AnimContainer key={linkObj.text + index}>
            <Link to={linkObj.link}>
              <motion.h2
                key={linkObj.text + "dfsas"}
                variants={slideUpVariants(0.5, 0.04 * index ** 2, 100)}
                className="home-link-text colour-bg"
              >
                {linkObj.text}
              </motion.h2>
            </Link>
          </AnimContainer>
        ))}
        <AnimContainer>
          <a href={resumeLink} target="_blank">
            <motion.div
              className="home-resume-link"
              onClick={() =>
                ReactGA.event({
                  category: "Open Resume",
                  action: "Open Resume (Home Link Mobile)",
                })
              }
              variants={slideUpVariants(0.5, 0.04 * homeLinks.length ** 2, 100)}
            >
              resume
            </motion.div>
          </a>
        </AnimContainer>
      </div>
    </motion.div>
  );
};

export default Homepage;
