import React from "react";
import { opacityVariants } from "../../utils/variants";
import VariantsContainer from "../VariantsContainer";
import { motion } from "framer-motion";

const FormFieldError = ({ msg }) => {
  return (
    <VariantsContainer>
      <motion.p
        variants={opacityVariants(0.2, 0)}
        className="form-filed-error-msg"
      >
        {msg}
      </motion.p>
    </VariantsContainer>
  );
};

export default FormFieldError;
