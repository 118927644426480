import React from "react";
import VariantsContainer from "../VariantsContainer";
import AnimContainer from "../AnimContainer";
import { slideUpVariants, slideDownVariants } from "../../utils/variants";
import { motion } from "framer-motion";

const ContactDetail = ({ name, detail }) => {
  return (
    <VariantsContainer classTitle="contact-details">
      <AnimContainer>
        <motion.h2
          variants={slideDownVariants(0.5, 0.1, 40)}
          className="contact-detail-name"
        >
          {name}
        </motion.h2>
      </AnimContainer>
      <AnimContainer>
        <motion.h3
          variants={slideUpVariants(0.5, 0.1, 40)}
          className="contact-detail-detail"
        >
          {detail}
        </motion.h3>
      </AnimContainer>
    </VariantsContainer>
  );
};

export default ContactDetail;
