import React from "react";
import AnimContainer from "./AnimContainer";
import VariantsContainer from "./VariantsContainer";
import { motion } from "framer-motion";
import {
  slideDownVariants,
  doNothingVariants,
  slideUpVariants,
} from "../utils/variants";
import { randomAngle } from "../utils/utils";

const HeadingComp = ({ heading }) => {
  const headingVariants = (index) => {
    return {
      init: { y: -20, rotate: 0, opacity: 0 },
      anim: {
        // y: (20 * index + 1) / 5,
        // rotate: randomAngle(0, 10),
        opacity: 1,
        transition: { type: "linear" },
      },
      //   exit: { y: 0 },
    };
  };

  return (
    <div className="heading-container">
      <VariantsContainer>
        <div className="header-letters-container">
          {heading.split("").map((letter, index) => (
            <motion.div
              variants={headingVariants(index)}
              key={index + letter + "dfxsw"}
              className="heading-text-container"
            >
              <AnimContainer>
                <motion.h4
                  className="heading-text"
                  variants={slideDownVariants(0.3, index / 20, 60)}
                >
                  {letter}
                </motion.h4>
              </AnimContainer>
            </motion.div>
          ))}
        </div>
      </VariantsContainer>
    </div>
  );
};

export default HeadingComp;
