import { motion } from "framer-motion";
import React from "react";
import AnimContainer from "../components/AnimContainer";
import HeadingComp from "../components/HeadingComp";
import PageMetaTags from "../components/PageMetaTags";
import VariantsContainer from "../components/VariantsContainer";
import {
  spokenLanguages,
  primaryFrameworks,
  programmingLanguages,
  programmingSkills,
  techStack,
  softSkills,
} from "../utils/icons";
import { opacityVariants, slideDownVariants } from "../utils/variants";

const SkillsPage = () => {
  const skillsLists = [
    { heading: "Programming Languages", skills: programmingLanguages },
    { heading: "Primary Frameworks", skills: primaryFrameworks },
    { heading: "Programming Skills", skills: programmingSkills },
    { heading: "Spoken Languages", skills: spokenLanguages },
    { heading: "Tech Stack", skills: techStack },
    { heading: "Soft Skills", skills: softSkills },
  ];

  const description =
    "Programming Languages: JavaScript, TypeScript, Python. Primary Frameworks: React JS, Django, Node JS. Programming: REST API Development, Object Oriented Programming, Responsive Design.";

  let i = 0;
  return (
    <VariantsContainer classTitle="skills-page-container">
      <HeadingComp heading="Skills" />

      <PageMetaTags
        title="Skills | Thulane's Portfolio"
        description={description}
      />

      <div className="skills-lists-container">
        {skillsLists.map((skillList, skillListIndex) => {
          return (
            <div
              key={skillList.heading + "wing"}
              className="skill-list-container"
            >
              <h3 className="skill-list-heading">{skillList.heading}</h3>
              {skillList.skills.map((skill, skillIndex) => {
                i++;
                return (
                  <AnimContainer key={skill.name + "42x"}>
                    <motion.div
                      variants={slideDownVariants(0.3, 0.1 + 0.065 * i)}
                      className="skill-list-item-container"
                    >
                      <h4 className="skill-list-item-text">{skill.name}</h4>
                      {skill.icons &&
                        skill.icons.map((icon, iconIndex) => (
                          <motion.img
                            key={icon}
                            variants={opacityVariants()}
                            src={icon}
                            alt={skill.name + icon}
                            className="skill-list-item-icon"
                          />
                        ))}
                    </motion.div>
                  </AnimContainer>
                );
              })}
            </div>
          );
        })}
      </div>
    </VariantsContainer>
  );
};

export default SkillsPage;
