import React, { useState, useRef, useEffect } from "react";
import * as PIXI from "pixi.js";
import {
  Stage,
  Sprite,
  AnimatedSprite,
  Container,
  withFilters,
} from "@inlet/react-pixi";
import finger1 from "../images/finger1.png";
import finger2 from "../images/finger2.png";
import particle0 from "../images/particle0.png";
import fingerS from "../images/finger-stretched.png";
import particle2 from "../images/particle2.png";
import particle3 from "../images/particle3.png";
import dm from "../images/nap.jpg";

const Hand = ({ fingerRef, mouseInit, showHandBtn, setShowHandBtn }) => {
  let hand;
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const scaleFactor = winHeight / 904 > 1.3 ? 1.3 : winHeight / 904;
  const containerRef = useRef();
  const stageRef = useRef();
  const handRef = useRef();
  const particleRef = useRef();
  const sprRef = useRef();

  const [animPlaying, setAnimPlaying] = useState(false);
  const [pos, setPos] = useState({
    x: mouseInit.x,
    y: mouseInit.y,
    timestamp: Date.now(),
  });

  const armAngle = () => {
    const xRatio = (pos.x - winWidth / 2) / winWidth / 2;
    const angleRad = xRatio * 1.5708;
    return angleRad;
  };

  const moveHand = (e) => {
    let pos = e.data.global;
    hand.x = pos.x;
    hand.y = pos.y;
    hand.rotation = armAngle(hand.x);
    // console.log(armAngle(hand.x), winHeight, hand.y);
  };
  // useEffect(() => {
  //   sprRef.current.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
  //   setRenderFilter(true);
  // }, []);

  return (
    <div>
      <div className="hand-container">
        <Stage
          ref={stageRef}
          options={{ transparent: true }}
          width={window.innerWidth}
          x={0}
          height={window.innerHeight}
        >
          <Container
            ref={containerRef}
            interactive
            mousemove={(e) => {
              !showHandBtn && setShowHandBtn(true);
              Date.now() - pos.timestamp > 5 &&
                setPos({
                  x: e.data.global.x,
                  y: e.data.global.y,
                  timestamp: Date.now(),
                });
            }}
            options={{ backgroundColor: "#ff1111" }}
          >
            {/* <AnimatedSprite
              images={[particle0, particle0, particle0, particle0, particle0]}
              onComplete={() => {
                // setAnimPlaying(false);
              }}
              ref={particleRef}
              alpha={0.4}
              animationSpeed={0.2}
              scale={scaleFactor}
              anchor={{ x: 0.4, y: 0.038 }}
              x={pos.x}
              y={pos.y}
              rotation={armAngle()}
              isPlaying={animPlaying}
              loop={false}
            /> */}
            <Sprite
              image={finger1}
              scale={scaleFactor}
              anchor={{ x: 0.4, y: 0.038 }}
              x={pos.x}
              y={pos.y}
              rotation={armAngle()}
              ref={handRef}
              // scale={scaleFactor}
              // x={pos.x}
              // y={pos.y}
              // rotation={armAngle()}
              // mousemove={(e) =>
              // //   setPos({ x: e.data.global.x, y: e.data.global.y })
              // }
              // interactive
            />
            <AnimatedSprite
              images={[particle0, fingerS, particle0, particle0]}
              isPlaying={animPlaying}
              ref={fingerRef}
              animationSpeed={0.2}
              scale={scaleFactor}
              anchor={{ x: 0.4, y: 0.038 }}
              x={pos.x}
              y={pos.y}
              rotation={armAngle()}
              loop={false}
              // loop={false}
              // scale={scaleFactor}
              // x={pos.x}
              // y={pos.y}
              // rotation={armAngle()}
              // mousemove={(e) =>
              // //   setPos({ x: e.data.global.x, y: e.data.global.y })
              // }
              // interactive
            />
          </Container>
        </Stage>
      </div>
    </div>
  );
};

export default Hand;
