import React from "react";
import { doNothingVariants } from "../utils/variants";
import { motion } from "framer-motion";

const VariantsContainer = ({ classTitle, children }) => {
  return (
    <motion.div
      variants={doNothingVariants}
      initial="init"
      animate="anim"
      exit="exit"
      className={classTitle}
    >
      {children}
    </motion.div>
  );
};

export default VariantsContainer;
