export const colourThemeName = "#b48464";
export const lightThemeName = "light";
export const darkThemeName = "dark";

export const resumeLink = "https://thulane.co.za/resume.pdf";

export const themes = [
  { name: colourThemeName, colour: "#634836" },
  { name: lightThemeName, colour: "#ddd" },
  { name: darkThemeName, colour: "#222" },
];
