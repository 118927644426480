import React from "react";

const HideHandBtns = ({ showHand, setShowHand, mousePosHandler }) => {
  return (
    <div className="hide-hand-btn-container">
      <button
        onClick={(e) => {
          mousePosHandler({ x: e.pageX, y: e.pageY });
          setShowHand((prev) => !prev);
        }}
        className="hide-hand-btn"
      >
        {showHand ? "Hide Hand" : "Show Hand"}
      </button>
    </div>
  );
};

export default HideHandBtns;
