import React from "react";
import {
  slideDownVariants,
  slideUpVariants,
  opacityVariants,
} from "../utils/variants";
import VariantsContainer from "./VariantsContainer";
import AnimContainer from "./AnimContainer";
import { motion } from "framer-motion";
import { lightThemeName } from "../utils/variables";
import linkedInImg from "../images/icons/linkedin.png";

const Footer = ({ selectedTheme }) => {
  const date = new Date();
  const year = date.getFullYear();
  const iconColour = selectedTheme === lightThemeName ? "#000" : "#fff";
  const strokeColour = "";

  return (
    <VariantsContainer classTitle="footer-container">
      <div className="footer-msg">best experienced on desktop.</div>
      <AnimContainer>
        {/* MAKE SVG BELOW LINKED IN LINK */}
        {/* <motion.svg
          variants={slideUpVariants(0.5, 0.2)}
          xmlns="http://www.w3.org/2000/svg"
          width="35.679"
          height="28.978"
          viewBox="0 0 35.679 28.978"
        >
          <path
            id="Icon_awesome-twitter"
            data-name="Icon awesome-twitter"
            d="M32.012,10.6c.023.317.023.634.023.951,0,9.667-7.358,20.805-20.805,20.805A20.664,20.664,0,0,1,0,29.076a15.127,15.127,0,0,0,1.766.091,14.645,14.645,0,0,0,9.078-3.124,7.325,7.325,0,0,1-6.837-5.071,9.222,9.222,0,0,0,1.381.113,7.734,7.734,0,0,0,1.924-.249,7.313,7.313,0,0,1-5.864-7.177v-.091a7.364,7.364,0,0,0,3.305.928A7.323,7.323,0,0,1,2.49,4.716a20.785,20.785,0,0,0,15.078,7.652,8.255,8.255,0,0,1-.181-1.675,7.319,7.319,0,0,1,12.655-5,14.4,14.4,0,0,0,4.641-1.766,7.293,7.293,0,0,1-3.215,4.03,14.659,14.659,0,0,0,4.211-1.132A15.719,15.719,0,0,1,32.012,10.6Z"
            transform="translate(0 -3.381)"
            fill={iconColour}
            stroke={strokeColour}
          />
        </motion.svg> */}
      </AnimContainer>
      <AnimContainer>
        <a href="https://linkedin.com/in/thulane-khumalo" target="_blank">
          <img
            src={linkedInImg}
            alt="linked in icon"
            className="linkedin-footer-img"
          />
        </a>
        <a href="https://github.com/tkdevv" target="_blank">
          <motion.svg
            variants={slideUpVariants(0.5, 0.3)}
            xmlns="http://www.w3.org/2000/svg"
            width="28.875"
            height="28.004"
            viewBox="0 0 34.875 34.004"
          >
            <path
              id="Icon_awesome-github"
              data-name="Icon awesome-github"
              d="M11.665,27.942c0,.141-.162.253-.366.253-.232.021-.394-.091-.394-.253,0-.141.162-.253.366-.253C11.482,27.668,11.665,27.78,11.665,27.942Zm-2.187-.316c-.049.141.091.3.3.345a.33.33,0,0,0,.436-.141c.042-.141-.091-.3-.3-.366A.361.361,0,0,0,9.478,27.626Zm3.108-.12c-.2.049-.345.183-.323.345.021.141.2.232.415.183s.345-.183.323-.323S12.79,27.485,12.586,27.506ZM17.212.563A16.86,16.86,0,0,0,0,17.719,17.628,17.628,0,0,0,11.918,34.537c.9.162,1.216-.394,1.216-.851,0-.436-.021-2.841-.021-4.317,0,0-4.922,1.055-5.955-2.1,0,0-.8-2.046-1.955-2.573,0,0-1.61-1.1.113-1.083A3.711,3.711,0,0,1,8.03,25.432a3.717,3.717,0,0,0,5.126,1.47,3.908,3.908,0,0,1,1.125-2.37c-3.93-.436-7.9-1.005-7.9-7.77a5.329,5.329,0,0,1,1.659-4.141,6.643,6.643,0,0,1,.183-4.774c1.47-.457,4.852,1.9,4.852,1.9a16.611,16.611,0,0,1,8.831,0s3.382-2.363,4.852-1.9a6.64,6.64,0,0,1,.183,4.774,5.466,5.466,0,0,1,1.814,4.141c0,6.785-4.141,7.327-8.072,7.77a4.158,4.158,0,0,1,1.2,3.263c0,2.37-.021,5.3-.021,5.878,0,.457.323,1.013,1.216.851a17.466,17.466,0,0,0,11.8-16.8C34.875,7.966,26.965.563,17.212.563ZM6.834,24.813c-.091.07-.07.232.049.366.113.112.274.162.366.07.091-.07.07-.232-.049-.366C7.087,24.771,6.926,24.722,6.834,24.813Zm-.759-.57c-.049.091.021.2.162.274a.209.209,0,0,0,.3-.049c.049-.091-.021-.2-.162-.274C6.237,24.152,6.124,24.173,6.075,24.244Zm2.278,2.5c-.113.091-.07.3.091.436.162.162.366.183.457.07.091-.091.049-.3-.091-.436C8.655,26.655,8.445,26.634,8.353,26.747Zm-.8-1.034c-.113.07-.113.253,0,.415s.3.232.394.162a.321.321,0,0,0,0-.436C7.847,25.692,7.664,25.622,7.552,25.713Z"
              transform="translate(0 -0.563)"
              fill={iconColour}
              stroke={strokeColour}
            />
          </motion.svg>
        </a>
      </AnimContainer>

      <h3 className="footer-year">{year}</h3>
    </VariantsContainer>
  );
};

export default Footer;
