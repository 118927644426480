// export const slideDownVariants = (delay=null, duration=null) =>{
//     return {
//         init: {y:60}
//     }
// }
export const slideUpVariants = (duration = 0.3, delay = 0.3, toSlide = 80) => {
  return {
    init: { y: toSlide },
    anim: {
      y: 0,
      transition: {
        type: "linear",
        duration,
        delay,
      },
    },
    exit: { y: toSlide },
  };
};

export const slideDownVariants = (
  duration = 0.3,
  delay = 0.3,
  toSlide = 80
) => {
  return {
    init: { y: -toSlide },
    anim: {
      y: 0,
      transition: {
        type: "linear",
        duration,
        delay,
      },
    },
    exit: {
      y: -toSlide,
      transition: {
        type: "linear",
        duration,
        // delay,
      },
    },
  };
};

export const doNothingVariants = {
  init: {},
  anim: {},
  exit: { opacity: 0, transition: { duration: 0.7 } },
};

export const opacityVariants = (duration = 0.3, delay = 0.2) => {
  return {
    init: { opacity: 0 },
    anim: {
      opacity: 1,
      transition: {
        type: "tween",
        duration,
        delay,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        type: "linear",
        duration,
        // delay,
      },
    },
  };
};
