import React, { useEffect } from "react";
import AnimContainer from "../components/AnimContainer";
import VariantsContainer from "../components/VariantsContainer";
import { motion } from "framer-motion";
import {
  slideDownVariants,
  slideUpVariants,
  opacityVariants,
} from "../utils/variants";
import HeadingComp from "../components/HeadingComp";
import PRJViewBtns from "../components/ProjectsPage/PRJViewBtns";
import { projects } from "../utils/projects";
import PageMetaTags from "../components/PageMetaTags";

const ProjectsPage = () => {
  const prjContentStyles = (index) => {
    return index % 2 == 0 ? "" : "";
    return index % 2 == 0 ? "" : " prj-content-reverse";
  };

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <VariantsContainer classTitle="projects-page-container">
      <PageMetaTags title="Projects | Thulane's Portfolio" />

      <HeadingComp heading="Projects" />
      <motion.div variants={opacityVariants()} className="projects-container">
        {projects.map((prj, index) => (
          <div key={prj.name} className="project-container">
            <div className={`prj-content${prjContentStyles(index)}`}>
              <div className="prj-image-wrapper">
                <div className="prj-image-container">
                  <motion.img
                    variants={opacityVariants(0.5, 0.5)}
                    transition={{
                      duration: 0.3,
                      ease: [0.45, 0.13, 0.23, 0.96],
                    }}
                    src={prj.image}
                    alt={prj.name.toLowerCase() + "-image"}
                  />
                </div>
              </div>
              <div className="prj-content-divider"></div>
              <div className="prj-text-container">
                <AnimContainer>
                  <motion.h1
                    variants={slideDownVariants()}
                    className="prj-name colour-bg"
                  >
                    {prj.name}
                  </motion.h1>
                  <div className="project-stack-container">
                    {prj.techStack &&
                      prj.techStack.map((tech) => (
                        <h4
                          key={tech + "dfssdx"}
                          className="project-stack-item"
                        >
                          {tech}
                        </h4>
                      ))}
                  </div>
                </AnimContainer>
                <AnimContainer>
                  <motion.p
                    variants={slideUpVariants(0.6, 0, 150)}
                    className="prj-description"
                  >
                    {prj.description}
                  </motion.p>
                </AnimContainer>
                <AnimContainer>
                  {/* <h3 className="see-more-btn">+See More</h3> */}
                </AnimContainer>
                <PRJViewBtns
                  githubLink={prj.githubLink}
                  siteLink={prj.siteLink}
                  prjName={prj.name}
                />
              </div>
            </div>
            <hr className="prj-hr" />
          </div>
        ))}
      </motion.div>
    </VariantsContainer>
  );
};

export default ProjectsPage;
