import React from "react";
import { motion } from "framer-motion";
import VariantsContainer from "./VariantsContainer";
import { randomAngle, randomNumber } from "../utils/utils";

const CircleText = ({ text, textRadius = 25, innerCircle, handler }) => {
  const degToRadians = (deg) => (deg * Math.PI) / 180;

  const textChars = text.split("");
  const textDiameter = textRadius * 2;
  const randomAngleVal = randomAngle(0, 270);
  const deltaAngle = 360 / textChars.length;

  const rotationVariants = () => ({
    init: { rotate: randomAngleVal },
    anim: {
      rotate: randomAngleVal + 360,
      transition: {
        type: "tween",
        ease: "linear",
        repeat: Infinity,
        duration: randomNumber(17, 25),
      },
    },
  });

  return (
    <VariantsContainer classTitle="">
      <motion.div
        // variants={rotationVariants()}
        onClick={handler}
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: textRadius + 1,
          height: textRadius + 1,
        }}
        className="circle-text-container"
      >
        {innerCircle && (
          <div
            style={{
              background: innerCircle,
              height: textRadius / 1.6,
              width: textRadius / 1.6,
            }}
            className="circle-text-inner-circle"
          ></div>
        )}
        {/* {textChars.map((char, index) => (
          <span
          key={char}
            style={{
              position: "absolute",
              transformOrigin: "top left",
              top:
                textRadius * -Math.cos(degToRadians(index * deltaAngle)) +
                textRadius,
              left:
                textRadius * Math.sin(degToRadians(index * deltaAngle)) +
                textRadius,
              // transform: `rotate(${index * deltaAngle}deg)`,
            }}
            className="circle-char"
          >
            {char}
          </span>
        ))} */}
      </motion.div>
    </VariantsContainer>
  );
};

export default CircleText;
