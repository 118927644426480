import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const PageMetaTags = ({ title, description }) => {
  useEffect(
    () => ReactGA.pageview(window.location.pathname + window.location.search),
    []
  );

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default PageMetaTags;
