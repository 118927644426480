export const randomAngle = (floor = 0, ceil = 1) => {
  const randomNumber = Math.floor(Math.random() * (ceil + 1)) + floor;
  return randomNumber < 0 ? 360 + randomNumber : randomNumber;
};

export const randomNumber = (floor = 0, ceil = 1) =>
  Math.floor(Math.random() * (ceil + 1)) + floor;

export const calculateAge = (birthday) => {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
