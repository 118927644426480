import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { opacityVariants } from "../../utils/variants";

const P = ({ children, index }) => {
  return (
    <AnimatePresence>
      <motion.p
        variants={opacityVariants(0.3, 0.1 + 0.05 * index)}
        className="about-me-text"
      >
        {children}
      </motion.p>
    </AnimatePresence>
  );
};

export default P;
