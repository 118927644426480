import ppImg from "../images/project-images/pp.png";
import c8Img from "../images/project-images/c8g.png";
import woImg from "../images/project-images/wo.png";
import lfImg from "../images/project-images/lf.png";
import mmImg from "../images/project-images/mm.png";
import repImg from "../images/project-images/rep.png";
import csImg from "../images/project-images/css.png";
import hamImg from "../images/project-images/ham.png";
import portImg from "../images/project-images/port.png";
import usImg from "../images/project-images/us.png";
import ceImg from "../images/project-images/ce.png";
import moonImg from "../images/project-images/blog.png";
import sapImg from "../images/project-images/sap.png";
import k1aImg from "../images/project-images/k1a.png";
import desktopImg from "../images/project-images/desktop.png";

const pabusPress = {
  name: "Pabu's Press",
  description:
    "Pabu's Press is a personal blog built for an aspiring author and dexterous writer. Because of his familiarity with Wordpress, we used it as CMS to serve posts which are then presented on a custom React JS front end. We set out to land on a beautiful design that collaborates with a solid feature set to create a fluid and pleasant user experience and I take great pride in saying we did just that.",
  image: ppImg,
  techStack: ["React JS", "Wordpress", "HTML", "CSS", "Sass", "Adobe XD"],
  // githubLink: "https://github.com/tkdevv",
  siteLink: "https://pabuspress.co.za",
};

const sneakAPreview = {
  name: "Sneak A Preview",
  description:
    "A web 3D showcase. This project is a demonstration of where web 3D graphics are today and how they can, in the right hands, be leveraged to create unique and fully immersive experiences in ways that did not exist before. I used JavaScript's most powerful 3D library ThreeJS along with React to develop this piece.",
  image: sapImg,
  techStack: ["React JS", "ThreeJS", "HTML", "CSS", "Sass", "Adobe XD"],
  siteLink: "https://demos.thulane.co.za/sneakpreview",
};

const mbaleeM = {
  name: "Mbalee M",
  description:
    "A landing page for Mbalee M, who's a digital content creator, built to be a temporary bridge connecting her audience to her socials and content distribution platforms, while she works on a more fleshed-out, content hosting web application. We went for a very stylised, handmade poster, markers & paper aesthetic to match her self-made content and both really loved the final result.",
  image: mmImg,
  techStack: ["React JS", "HTML", "CSS", "Sass", "Adobe XD"],
  // githubLink: "https://github.com/tkdevv",
  siteLink: "https://mbaleem.co.za",
};

const carShop = {
  name: "Car Showcase",
  description:
    "A car showcase to preview all the vehicles available for purchase at a small local used car dealership. The simple, modern and sleek UI is coupled with a cloud based backend allowing for seamless social authentication, authorisation and permissions as well as vehicle management all from the client. It also features a custom animated image lightbox. The deployment of the full production version will be live very soon as it is currently in the final stages of development.",
  image: csImg,
  techStack: ["React JS", "Firebase", "HTML", "CSS", "Sass", "Adobe XD"],
  siteLink: "https://demos.thulane.co.za/car-showcase",
};

const k1a = {
  name: "K1 Autowash",
  description:
    "This is the landing page for K1 Autowash, a local car wash facility in my area. It showcases my grasp of design fundamentals, responsive design and colour theory. As the ability to transform Adobe XD designs into functional production ready projects. I programmed it using React JS and Typescript.",
  image: k1aImg,
  techStack: ["React JS", "Adobe XD", "HTML", "CSS", "Sass"],
  siteLink: "https://k1autowash.co.za",
};

const portfolio = {
  name: "Personal Portfolio",
  description:
    "This very site. Built with React JS to be the central repository for my projects and a bio for my developer journey. I believe it successfully introduces my character, highlights my technical skills and showcases my experience. It features smooth transition animations amongst other UI-UX elements you've probably noticed by now.",
  image: portImg,
  techStack: ["React JS", "HTML", "CSS", "Sass", "Adobe XD"],
  siteLink: "https://thulane.co.za",
};

const moon21 = {
  name: "Moon 21",
  description:
    "The best blog in the world; or at least one of the best conceptually. Moon 21 houses a number of posts that are each their own contained experiences straying from the template based convention. Each post is interactive and unique from the rest allowing for full expression of ideas without format and content constraints. An architecturally unconventional project that makes for a special sight (pun intended).",
  image: moonImg,
  techStack: ["React JS", "HTML", "CSS", "Sass", "Adobe XD"],
  siteLink: "https://moon21.co.za",
};

const chromeExtension = {
  name: "Pabu's Press Chrome Extension",
  description:
    "A chrome extension for Pabu's Press, the above mentioned project. It is a quick launcher which allows users to view and open the latest posts and links to the blog's social media profiles, newsletter sign-up and the main site. This particular project had its own set of unique challenges like distributing to the Chrome Web Store and drafting a privacy policy which I found interesting.",
  image: ceImg,
  techStack: ["React JS", "HTML", "CSS", "Adobe XD"],
  // githubLink: "https://github.com/tkdevv",
  siteLink:
    "https://chrome.google.com/webstore/detail/pabus-press/ilgalnmhbhnbnlhlnaebdnjkobmkdelj",
};

const halfAMinute = {
  name: "Half A Minute",
  description:
    "Half a Minute is on online trivia game with over 500 unique playing cards, programmatically gathered through a custom automation python script. The cards are then served from a custom Django RESTful API backend. The client side adds an easy to navigate and objectively cute user-interface, team customisation, a die, a team member randomiser and a countdown timer for users' convenience. Being a progressive web application (PWA), users can also save it as an app and use it offline at their leisure.",
  image: hamImg,
  techStack: [
    "React JS",
    "Django",
    "Beautiful Soup",
    "HTML",
    "CSS",
    "Sass",
    "Adobe XD",
  ],
  siteLink: "https://halfaminute.co.za/",
};

const repairShop = {
  name: "Repair Shop",
  description:
    "Repairs is a full-stack backend dashboard built for repair shops to manage their pending and completed repairs. It has fully functional authentication (with email verification) and profile management. As well as auto-user notification through sms and email APIs upon repair completion. I sold the source code so is not on github but I can share it w/ you in person. I'm working on permission to preview a live demonstration.",
  image: repImg,
  techStack: [
    "React JS",
    "Django",
    "Django Rest Framework",
    "HTML",
    "CSS",
    "Sass",
    "Figma",
  ],
};

const urbaneSoftware = {
  name: "Urbane Software",
  description:
    "Urbane Software is a tech company in Johannesburg that offers software development services. I collaborated with them in primarily designing and developing their landing page and secondarily creating their logo. The site features fluid animations on desktop, uses a modern design layout and is fully responsive.",
  image: usImg,
  techStack: ["React JS", "GSAP", "HTML", "CSS", "Sass", "Figma"],

  siteLink: "https://urbanesoftware.co.za/",
};

const watchOut = {
  name: "Watch Store",
  description:
    "Watch Store is a design of an online luxury time piece store. I built it to display my design capabilities as well as showcase my understanding of not only design fundamentals but the technical know how to turn designs and wireframes into interactive, dynamic, feature rich and beautiful applications. It is built purely using React JS.",
  image: woImg,
  techStack: ["React JS", "Framer Motion", "HTML", "CSS", "Sass", "Adobe XD"],
  githubLink: "https://github.com/tkdevv/watch-store",
  siteLink: "https://demos.thulane.co.za/watch-store",
};

const lyricsFinder = {
  name: "Onlyrics",
  description:
    "My first ever React application. Onlyrics is an app to find song lyrics. It is as easy to use as it is on the eye. With a minimalistic aesthetic, it achieves all its meant to do without the clutter of doing much else.",
  image: lfImg,
  techStack: ["React JS", "HTML", "CSS", "Sass"],
  githubLink: "https://github.com/tkdevv/lyrics-finder",
  siteLink: "https://onlyrics.herokuapp.com",
};

const desktop = {
  name: "Web Desktop",
  description:
    "This is a must see Windows 98 and desktop inspired web user interface. While the project never received its final layer of polish and went to production, it stands as a great success, not only as a proof of concept for the utility and versatility of unique web experiences but also as a display of my abilities to programme complex layouts and turn a great variety of designs into pixel perfect frontends.",
  image: desktopImg,
  techStack: ["React JS", "HTML", "CSS", "Framer Motion", "Sass"],
  siteLink: "https://demos.thulane.co.za/desktop",
};

const c8 = {
  name: "C8",
  description:
    "An aplha version online multiplayer card game with text chat as well as voice chat functionality. User interaction is not between the application and the user as is typical, but rather between the users in a peer-to-peer connection, the application is just a medium for those interactions. It's crafted using React in the frontend, Node JS, Socket.io and WebRTC in the backend.",
  image: c8Img,
  techStack: [
    "React JS",
    "Node JS",
    "Socket.io",
    "Peer JS",
    "HTML",
    "CSS",
    "Sass",
    "Adobe XD",
  ],
  githubLink: "https://github.com/tkdevv/c8-frontend",
  siteLink: "https://demos.thulane.co.za/crazy8",
};

export const projects = [
  urbaneSoftware,
  pabusPress,
  desktop,
  halfAMinute,
  k1a,
  moon21,
  carShop,
  sneakAPreview,
  chromeExtension,
  c8,
  mbaleeM,
  portfolio,
  watchOut,
  lyricsFinder,
  repairShop,
];

export const summary =
  "I have a love for crafting. Building things that exist to serve a purpose. That purpose could be to make a difference, to make money or to make a certain task easier. I perpetually practice and study programming to get better at constructing those solutions. When I'm not programming, I'm the best player on the soccer team or capturing 24 images per second then rendering them as mini movies purely for my and my friends and family's enjoyment.";

export const langAndFrameworks = [
  "Spoken languages are English and isiZulu.",
  "Programming languages are TypeScript, JavaScript and Python.",
  "React JS and Django are my most proficient frameworks.",
];

export const skills = [
  "Single Page Application Development.",
  "Creating REST APIs",
  "State Management (Redux and Context API)",
  "Progressive Web Application Development",
  "Object Oriented Programming",
  "Realtime Updating Applications with Socket.IO",
  "Responsive Design",
  "Mobile App Development (with React Native)",
];

export const techStack = [
  "Git & GitHub.",
  "Adobe XD & Photoshop",
  "Wordpress",
  "Premiere Pro",
  "Microsoft Office",
  "MacOS, Windows & Linux",
  "Blender",
];
