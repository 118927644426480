import React, { useState } from "react";
import VariantsContainer from "../components/VariantsContainer";
import HeadingComp from "../components/HeadingComp";
import ContactDetail from "../components/ContactsPage/ContactDetail";
import { useForm } from "react-hook-form";
import AnimContainer from "../components/AnimContainer";
import { motion } from "framer-motion";
import {
  opacityVariants,
  slideDownVariants,
  slideUpVariants,
} from "../utils/variants";
import emailjs from "emailjs-com";
import FormFieldError from "../components/ContactsPage/FormFieldError";
import PageMetaTags from "../components/PageMetaTags";
import successImg from "../images/success.png";

const ContactsPage = () => {
  const { register, handleSubmit, errors } = useForm();
  const [{ loading, success }, setLoading] = useState({
    loading: false,
    success: null,
  });

  const submitForm = (data, e) => {
    setLoading({ loading: true, success: null });
    emailjs
      .sendForm("pp", "tkdevtemplateid", e.target, "user_jw0mWcZ6PAjV4NtBWu2r9")
      .then((result) => {
        e.target.name.value = "";
        e.target.email.value = "";
        e.target.message.value = "";
        setLoading({ loading: false, success: true });
      })
      .catch((error) => {
        console.log(error);
        setLoading({ loading: false, success: false });
      });
  };

  const inputClass = (inputName) => {
    if (errors[inputName]) {
      return " form-input-error";
    }
    return "";
  };

  const description =
    "Contacts. Email: thulane.dev@gmail.com, Phone: +27 68 538 4578";

  return (
    <VariantsContainer classTitle="contacts-page-container">
      <PageMetaTags
        title="Contacts | Thulane's Portfolio"
        description={description}
      />
      <HeadingComp heading="Contacts" />
      <div className="contacts-container">
        <div className="contact-details-container">
          <ContactDetail name="phone" detail="+27 68 538 4578" />
          <ContactDetail name="email" detail="thulane.dev@gmail.com" />
        </div>
        <div className="contact-form-container">
          {!success ? (
            <form onSubmit={handleSubmit(submitForm)} className="contact-form">
              <AnimContainer>
                <motion.input
                  variants={opacityVariants(0.5, undefined)}
                  placeholder="name"
                  ref={register({ required: true })}
                  name="name"
                  type="text"
                  className={"form-input" + inputClass("name")}
                />
              </AnimContainer>
              {errors.name && <FormFieldError msg="please enter your name" />}

              <AnimContainer>
                <motion.input
                  variants={opacityVariants(0.5, undefined)}
                  placeholder="email"
                  ref={register({
                    required: true,
                    pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
                  })}
                  name="email"
                  type="email"
                  className={"form-input" + inputClass("email")}
                />
              </AnimContainer>
              {errors.email && (
                <FormFieldError msg="please enter a valid email address" />
              )}

              <AnimContainer>
                <motion.textarea
                  variants={opacityVariants(0.5, undefined)}
                  placeholder="message"
                  ref={register({ required: true })}
                  name="message"
                  cols="30"
                  rows="10"
                  className={"textarea-input" + inputClass("message")}
                ></motion.textarea>
              </AnimContainer>
              {errors.message && (
                <FormFieldError msg="please enter your message." />
              )}

              <AnimContainer>
                <motion.button
                  disabled={success || loading}
                  style={{
                    border: success
                      ? "0px solid #11ff11"
                      : success === false
                      ? "2px solid #ff1111"
                      : "none",
                  }}
                  variants={slideUpVariants(0.5, undefined, 60)}
                  type="submit"
                  className="contact-submit-btn"
                >
                  {loading
                    ? "Sending Message"
                    : success
                    ? "Message Sent"
                    : success === false
                    ? "Failed, Try Again"
                    : "Send Message"}
                </motion.button>
              </AnimContainer>
            </form>
          ) : (
            <div className="form-success-container">
              <img
                src={successImg}
                alt="success green check"
                className="form-sent-tick"
              />
              <h3 className="contact-form-success-text">
                Thank you for reaching out. Your message has been sent.
              </h3>
            </div>
          )}
        </div>
      </div>
    </VariantsContainer>
  );
};

export default ContactsPage;
