import reactIcon from "../images/icons/react.png";
import djangoIcon from "../images/icons/django.png";
import htmlIcon from "../images/icons/html.png";
import cssIcon from "../images/icons/css.png";
import javascriptIcon from "../images/icons/javascript.png";
import officeIcon from "../images/icons/office.png";
import premIcon from "../images/icons/prem.png";
import psIcon from "../images/icons/ps.png";
import pythonIcon from "../images/icons/python.png";
import sassIcon from "../images/icons/sass.png";
import typescriptIcon from "../images/icons/typescript.png";
import wordpressIcon from "../images/icons/wordpress.png";
import xdIcon from "../images/icons/xd.png";
import blenderIcon from "../images/icons/blender.png";
import davinciIcon from "../images/icons/davinci.png";
import figmaIcon from "../images/icons/figma.png";
import linuxIcon from "../images/icons/linux.png";
import macIcon from "../images/icons/mac.png";
import windowsIcon from "../images/icons/windows.png";
import nodejsIcon from "../images/icons/nodejs.png";
import githubIcon from "../images/icons/github.png";

export const icons = [
  { name: "Blender", icon: blenderIcon },
  { name: "CSS", icon: cssIcon },
  { name: "Davinci", icon: davinciIcon },
  { name: "Figma", icon: figmaIcon },
  { name: "Django", icon: djangoIcon },
  { name: "HTML", icon: htmlIcon },
  { name: "JavaScript", icon: javascriptIcon },
  { name: "Linux", icon: linuxIcon },
  { name: "Mac", icon: macIcon },
  { name: "Windows", icon: windowsIcon },
  { name: "Node JS", icon: nodejsIcon },
  { name: "Microsoft Office", icon: officeIcon },
  { name: "Premiere Pro", icon: premIcon },
  { name: "Photoshop", icon: psIcon },
  { name: "Python", icon: pythonIcon },
  { name: "React", icon: reactIcon },
  { name: "Sass", icon: sassIcon },
  { name: "TypeScript", icon: typescriptIcon },
  { name: "Wordpress", icon: wordpressIcon },
  { name: "Adobe XD", icon: xdIcon },
];

export const spokenLanguages = [{ name: "English" }, { name: "isiZulu" }];

export const programmingLanguages = [
  { name: "TypeScript", icons: [typescriptIcon] },
  { name: "JavaScript", icons: [javascriptIcon] },
  { name: "HTML, CSS & SASS", icons: [htmlIcon, cssIcon, sassIcon] },
  { name: "Python", icons: [pythonIcon] },
];

export const primaryFrameworks = [
  { name: "React JS", icons: [reactIcon] },
  { name: "Django", icons: [djangoIcon] },
  { name: "Node JS", icons: [nodejsIcon] },
];

export const techStack = [
  { name: "Git & GitHub", icons: [githubIcon] },
  { name: "Adobe XD & Figma", icons: [xdIcon, figmaIcon] },
  { name: "Wordpress", icons: [wordpressIcon] },
  { name: "Microsoft Office", icons: [officeIcon] },
  { name: "Windows", icons: [windowsIcon] },
  { name: "MacOS & Linux", icons: [macIcon, linuxIcon] },
  { name: "Premiere Pro & Davinci", icons: [premIcon, davinciIcon] },
  { name: "Blender", icons: [blenderIcon] },
];

export const programmingSkills = [
  { name: "REST API" },
  { name: "PWA" },
  { name: "Single Page Apps" },
  { name: "State Management" },
  { name: "Object Oriented Programming" },
  { name: "Responsive Design" },
  { name: "Mobile Apps (React Native)", icons: [reactIcon] },
];

export const softSkills = [
  { name: "Problem Solver" },
  { name: "Decisive" },
  { name: "Self Motivated" },
  { name: "Creative Thinker" },
  { name: "Good Communication" },
];
